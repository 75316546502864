import React from "react";

import * as styles from "./text-block.module.scss";

function TextBlock(props) {
  return (
    <div className={styles.root}>
      {props.children}
    </div>
  );
}

export default TextBlock;

