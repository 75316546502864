import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import SEO from "../components/seo";
import { cn } from "../lib/helpers";
import Grid from "../components/grid";
import TextBlock from "../components/text-block";
import ExitButton from "../components/exit-button";

import * as styles from "./team.module.scss";

// markup
const TeamPage = () => {
  return (
    <>
      <SEO title="Team" />
      <ExitButton />
      <h1 className="hide-on-desktop">Team</h1>
      <Grid>
        <TextBlock>
          <h4>Adi Chugh<br/>Founder &amp; CEO</h4>
          <div className={styles.mobileImage}>
            <StaticImage src="../images/adi-chugh.jpg" alt="Adi Chugh" />
          </div>
          <p>Adi Chugh is the Founder and CEO of Surya Capital Partners, formerly known as Maverick Commercial Properties. He brings significant experience from working on Wall Street across various sectors and across the balance sheet.</p>
          <p>Adi founded Surya Capital Partners at the end of 2010 as he identified an opportunity in the distressed real estate space. He created a plethora of relationships across the lending world, all the way from top private equity firms to large institutional investors. He has leveraged his network to deliver bespoke financing solutions to his clients.</p>
          <p>Adi has also created significant strategic partnerships within the real estate business by leveraging his investment banking knowledge and creating a holistic platform for his clients; ranging from advising on platform level joint ventures, Co-GP investments and advising on the entire capital stack.</p>
          <p>Adi is also very active in the technology space and is an avid venture investor. He has launched a finance matchmaking platform called Remissary (remissary.com), along with his new tech venture in the alcoholic beverage space.</p>
          <p>Adi began his career as an Investment Banker with Wachovia Securities in their Leverage Finance Group. He then moved onto the Equity Capital Markets Group in New York. Adi placed approximately $16 billion of debt in his time at Wachovia. Adi has had the opportunity of working on some marquis transactions such as the Oracle & Peoplesoft acquisition (a $9.5 bn transaction), Adolph Coor’s acquisition of Molson, Inc. (a $1.3 bn acquisition) and has worked with marquis names in the retail, media & telecom and energy sectors.</p>
          <p>From there, Adi moved onto becoming Vice President and Head of the West Coast (North America) for Kotak Mahindra (One of the largest Indian Investment Banks) and working for Merrill Lynch in New York. As an India specialist, Adi was primarily involved in raising assets from U.S. investors for investing into India and developing a platform for investors and firms looking to enter strategic partnerships in India.</p>
          <p>Adi is a native of New Delhi and completed his degrees in International Business &amp; Management and Economics from Dickinson College in Carlisle, PA</p>
          <p><a href="mailto:adi@suryacp.com">adi@suryacp.com</a></p>
        </TextBlock>
        <div className={styles.imageWrapper}>
          <StaticImage src="../images/adi-chugh.jpg" alt="Adi Chugh" />
        </div>
      </Grid>
      <Grid>
        <TextBlock>
          <h4>Richard Levin<br/>Vice President</h4>
          <p>Richard Levin is a Vice President at Surya Capital Partners. Rich joined Surya Capital Partners at the beginning of 2019 as an Analyst and has had to opportunity to directly participate in the execution of over $2.0 Billion in debt and equity financing.</p>
          <p>Rich graduated from Miami University with a B.A. in Economics and Business Analytics.</p>
          <p><a href="mailto:richard@suryacp.com">richard@suryacp.com</a></p>
        </TextBlock>
      </Grid>
      <Grid>
        <TextBlock>
          <h4>Chris McDaniel<br/>Managing Director</h4>
          <div className={cn(styles.mobileImage, styles.isPortrait)}>
            <StaticImage src="../images/chris-mcdaniel.jpg" alt="Chris McDaniel" />
          </div>
          <p>Chris McDaniel is a Managing Director of Surya Capital Partners. Chris joined Surya Capital Partners at the end of 2018 and has over seventeen years of nationwide experience originating bridge and permanent loans in the capital markets and on balance sheet.</p>
          <p>Prior to Surya Capital Partners, Chris held numerous senior lending positions at JPMorgan, Argentic Real Estate Finance (f/k/a/ Silverpeak), M&amp;T Bank and A10 Capital and was responsible for originating and structuring billions of dollars of loans for a wide range of institutional and family-controlled borrowers.</p>
          <p>Chris began his banking career at M&amp;T Bank, following his completion of their relationship manager credit training program. Throughout his career he has successfully completed transactions across all real asset classes. Further, he is adept at structuring debt &amp; equity executions through all stages of the real estate financing cycle, from land acquisition &amp; construction to bridge and permanent financing.</p>
          <p>Chris completed his B.A. degree at Connecticut College and holds an MBA from Columbia Business School, as well as a JD from American University and is a member of the New York Bar.</p>
          <p><a href="mailto:chris@suryacp.com">chris@suryacp.com</a></p>
        </TextBlock>
        <div className={cn(styles.imageWrapper, styles.isPortrait)}>
          <StaticImage src="../images/chris-mcdaniel.jpg" alt="Chris McDaniel" />
        </div>
      </Grid>
      <Grid>
        <TextBlock>
          <h4>Christian Hung<br/>Director</h4>
          <div className={cn(styles.mobileImage, styles.isPortrait)}>
            <StaticImage src="../images/christian-hung.jpg" alt="Christian Hung" />
          </div>
          <p>Christian Hung is a Director at Surya Capital Partners.</p>
          <p>Prior to joining Surya Capital Partners in October 2020, Christian spent over seven years at Deutsche Bank in the Real Estate Special Situations Group, a principal debt investment group focused on investing the bank’s balance sheet capital in the real estate sector, as a Vice President. From 2013 to 2020, Christian was responsible for sourcing, structuring, and executing high-yield real estate loan originations.</p>
          <p>Christian began his career as an Investment Banking Analyst at Houlihan Lokey, an American multinational independent investment bank and financial services company, and as a Financial Analyst at Magnus Capital Partners, a U.S.-based private real estate investment firm that invests across property types through value-add strategies.</p>
          <p>Christian graduated from The Wharton School at the University of Pennsylvania with a Bachelor of Science in Economics degree with concentration in finance and real estate. Christian is a native speaker of Mandarin Chinese.</p>
          <p><a href="mailto:christian@suryacp.com">christian@suryacp.com</a></p>
        </TextBlock>
        <div className={cn(styles.imageWrapper, styles.isPortrait)}>
          <StaticImage src="../images/christian-hung.jpg" alt="Christian Hung" />
        </div>
      </Grid>
      <Grid>
        <TextBlock>
          <h4>Katrina Juncaj<br/>Executive Assistant</h4>
          <div className={cn(styles.mobileImage, styles.isPortrait)}>
            <StaticImage src="../images/katrina-juncaj.jpg" alt="Katrina Juncaj" />
          </div>
          <p>Katrina Juncaj is the Executive Assistant to the founder at Surya Capital Partners. She also assists with the firms’ business development endeavors and interfacing with clientele.</p>
          <p>Katrina has worked for several years in business development and managing both the personal and work schedules of busy professionals. Katrina worked for Atlanta based firms Yieldi and Fitmetrix. She developed a level of diligence and attention to detail which has allowed her to excel in her field. Katrina has established a wide network of friends and colleagues in the finance and banking space.</p>
          <p>Originally from Detroit, Katrina considers herself a New Yorker at heart, having lived in the big apple for over 12 years. She is a natural people person and a planner, with excellent organizational skills. Her personal passions include fashion, fitness, and outdoor activities.</p>
          <p><a href="mailto:katrina@suryacp.com">katrina@suryacp.com</a></p>
        </TextBlock>
        <div className={cn(styles.imageWrapper, styles.isPortrait)}>
          <StaticImage src="../images/katrina-juncaj.jpg" alt="Katrina Juncaj" />
        </div>
      </Grid>
    </>
  )
};

export default TeamPage;